<template>
    <b-modal
        id="modal-supplier"
        cancel-variant="secondary"
        ok-title="Tambah"
        cancel-title="Batal"
        centered
        title="Form Supplier"
    >
        <b-form>
            <b-form-group class="mb-2">
                <label for="jabatan">Nama</label>
                <b-form-input v-model="form.nama" id="nama" type="text" placeholder="Ex: Budi" />
            </b-form-group>
            <b-form-group class="mb-2">
                <label for="instansi">Instansi</label>
                <b-form-input v-model="form.instansi" id="instansi" type="text" placeholder="Ex: PT. Indo Jaya" />
            </b-form-group>
            <b-form-group class="mb-2">
                <label for="email">Email</label>
                <b-form-input v-model="form.email" id="email" type="email" placeholder="Ex: supplier@gmail.com" />
            </b-form-group>
            <b-form-group class="mb-2">
                <label for="nohp">No. Handphone</label>
                <b-form-input v-model="form.nohp" id="nohp" type="text" placeholder="Ex: 08888888888" />
            </b-form-group>
            <b-form-group class="mb-2">
                <label for="alamat">Alamat (opsional)</label>
                <b-form-textarea v-model="form.alamat"></b-form-textarea>
            </b-form-group>
        </b-form>

        <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
                <b-button :disabled="!isValidForm" size="sm" class="mr-1" variant="primary" @click="submit">
                    Simpan
                </b-button>
            </section>
        </template>
    </b-modal>
</template>
<script>
import {BModal, BButton, BForm, BFormGroup, BFormInput, BFormTextarea} from 'bootstrap-vue'
export default {
    props: ['item'],
    components: {
        BModal, BButton, BForm, BFormGroup, BFormInput, BFormTextarea
    },
    watch: {
        item(val) {
            if(val.nama) {
                this.form.nama = val.nama
                this.form.instansi = val.instansi
                this.form.email = val.email
                this.form.nohp = val.nohp
                this.form.alamat = val.alamat
            }
        }
    },
    data: () => ({
        editMode: false,
        form: {
            nama: null,
            instansi: null,
            email: null,
            nohp: null,
            alamat: null,
        }
    }),
    computed: {
        isValidForm() {
            if(!this.form.nama || !this.form.instansi || !this.form.email || !this.form.nohp) {
                return false
            }

            return true
        }
    },
    methods: {
        resetForm() {
            this.form.nama = null
            this.form.instansi = null
            this.form.email = null
            this.form.nohp = null
            this.form.alamat = null
        },
        submit() {
            if(!this.isValidForm) {
                this.displayError({
                    message: 'Harap lengkapi form'
                })
            }

            const payload = Object.assign({}, this.form)
            if(!payload.alamat) {
                payload.alamat = '-'
            }

            this.$emit('submit', payload, (status) => {
                if(status) this.resetForm
            })
            setTimeout(() => this.resetForm(), 500)
        }
    }
}
</script>